import { useEffect, useRef, useState } from "react";
import Drawer from 'react-modern-drawer';
import { useMediaQuery } from 'react-responsive';

import styles from './Menu.module.css';

import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as MenuDividerIcon } from "../../assets/icons/menu-divider.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook-rect.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter-rect.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram-rect.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube-rect.svg";

import internalpageBackground from "../../images/internalpage-background.webp";
import internalpageLogo from "../../images/internalpage-qtem-logo.png";

import Item from "./Item";
import SubItem from "./SubItem";

import { GameData, internalData0 } from "../../data/markers";
import { LINKS } from "../../data/links";

const Menu = ({ onChapter, setIsMenuOrPopupOpen }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    setIsMenuOrPopupOpen(!isOpen);
  }
  const isMobile = useMediaQuery({
    query: '(max-width: 980px)'
  });

  useEffect(() => {
    if (isOpen && ref) {
      ref.current.scrollTo(0, 0);
    }
  }, [isOpen]);

  return (
      <>
        <div className={styles["menu-icon"]}>
          <MenuIcon onClick={toggleDrawer} />
        </div>
        <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction={isMobile ? "bottom" : "right"}
            zIndex={900}
            style={{
              backgroundImage: internalpageBackground,
            }}
            className={styles["menu-container"]}
        >
          <div className={styles.close} onClick={() => { setIsOpen(false); setIsMenuOrPopupOpen(false); }} />
          <div className={styles.border}></div>
          <div className={styles.container} ref={ref}>
            <div className={styles.wrapper}>
              <div className={styles.body}>
                <img
                    className={styles.logo}
                    src={internalpageLogo}
                    alt="qtem logo"
                />
                <Item>
                  <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        onChapter(internalData0.modal);
                      }}
                  >
                    {internalData0.modal.title}
                  </a>
                </Item>
                <Item>Chapters</Item>
                {GameData.map((item) => (
                    <SubItem
                        key={item.modal.title}
                        onClick={() => onChapter(item.modal)}
                    >
                      {item.modal.title}
                    </SubItem>
                ))}
                <MenuDividerIcon width="100%" style={{ marginBottom: 30 }} />
                <Item>
                  <a href={LINKS.privacy} className="" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                </Item>
                <Item>
                  <a href={LINKS.contact} className="" target="_blank" rel="noreferrer">
                    Contact
                  </a>
                </Item>
                <div className={styles.social}>
                  <a href={LINKS.instagram} className="" target="_blank" rel="noreferrer">
                    <InstagramIcon />
                  </a>
                  <a href={LINKS.facebook} className="" target="_blank" rel="noreferrer">
                    <FacebookIcon />
                  </a>
                  <a href={LINKS.twitter} className="" target="_blank" rel="noreferrer">
                    <TwitterIcon />
                  </a>
                  <a href={LINKS.youtube} className="" target="_blank" rel="noreferrer">
                    <YoutubeIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </>
  )
}

export default Menu;
