import React, { useState } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import ReCAPTCHA from "react-google-recaptcha";
import styles from './NewsLetterPopup.module.css';

const MAILCHIMP_URL = 'https://games.us9.list-manage.com/subscribe/post?u=05d595e21d9b62bf55d17d647&amp;id=b4657e156a&amp;f_id=002720e1f0';
const RECAPTCHA_SITE_KEY = '6LcT6X0pAAAAAH0VANixPgZIhTaKcCEtArwDL45v';

const NewsletterPopup = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState('');

    const handleSubmit = (e, subscribe) => {
        e.preventDefault();
        if (!recaptchaValue) {
            alert("Please complete the reCAPTCHA verification.");
            return;
        }
        email && email.indexOf("@") > -1 && subscribe({ EMAIL: email });
    };

    return (
        <MailchimpSubscribe
            url={MAILCHIMP_URL}
            render={({ subscribe, status, message }) => (
                <div className={styles["newsletter-popup-container"]}>
                    <form className={styles["newsletter-popup"]} onSubmit={(e) => handleSubmit(e, subscribe)}>
                        {status !== "success" ? (
                            <>
                                <h2>Stay in the loop!</h2>
                                <p>Sign up with your e-mail to receive exclusive updates, sneak peeks and news about our game. Don't miss out on the latest developments!</p>
                                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                                <ReCAPTCHA
                                    sitekey={RECAPTCHA_SITE_KEY}
                                    onChange={(value) => setRecaptchaValue(value)}
                                />
                                <p style={{ fontSize: '12px' }}>Don't worry, we won't flood you with spam. Only emails regarding Inoutbox Games company news. By submitting the form, you agree to our privacy policy.</p>
                                {status === "error" && (
                                    <div className={styles.error}>{message}</div>
                                )}
                                <div className={styles.action}>
                                    <button className="btn-follow" onClick={() => onClose()}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn-follow" disabled={status === "sending"}>
                                        {status === "sending" ? "Sending" : 'Send'}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <p>Thank you for your interest. We will keep you informed exclusively with important news about our company and the game QTEM: Quick Time Event Master.<br />
                                    We have sent you a confirmation email. Please check your email in about 10-15 minutes. If you haven't received the email, check the "UPDATES" or "SPAM" folder, as emails can sometimes end up there.</p>
                                <div className={styles.action}>
                                    <button className="btn-follow" onClick={() => onClose()}>
                                        Ok
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            )}
        />
    )
};

export default NewsletterPopup;
