import styles from './SubItem.module.css';

const SubItem = ({ children, onClick }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.text}>
        {children}
      </div>
    </div>
  )
}

export default SubItem