import React, { useState } from "react";
import "./FixedButtons.css";
import ReactGA from "react-ga";
import ReactModal from "react-modal";
import { LINKS } from "../../data/links";
import NewsletterPopup from "../NewsLetterPopup/NewsLetterPopup";

const FixedButtons = () => {
  const [openNewsletter, setOpenNewsletter] = useState(false);

  const handleAboutClick = () => {
    window.open(LINKS.about, "_blank");
    ReactGA.event({
      category: "Button",
      action: "Clicked About Button",
    });
  };

  const handleSubscribeClick = () => {
    setOpenNewsletter(true);
    ReactGA.event({
      category: "Button",
      action: "Clicked Subscribe Button",
    });
  };

  const handleCloseNewsletter = () => {
    setOpenNewsletter(false);
  };

  return (
    <div className="fixed-buttons-container">
      <button className="about-button" onClick={handleAboutClick} aria-label="Learn more about us"></button>
      <button className="sub-button" onClick={handleSubscribeClick} aria-label="Subscribe to newsletter"></button>
      <button className="kick-button" aria-label="Kick"></button>

      <ReactModal
        isOpen={openNewsletter}
        onRequestClose={handleCloseNewsletter}
        style={{
          overlay: {
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            inset: "auto",
            background: "#F6F2E3",
            filter: "drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.10))",
          },
        }}
      >
        <NewsletterPopup onClose={handleCloseNewsletter} />
      </ReactModal>
    </div>
  );
};

export default FixedButtons;
