import React, { useState, useEffect } from 'react';
import preloaderGif from '../../images/preloader.gif';
import internalPageBackground from '../../images/internalpage-background.webp';

function Preloader({ hidePreloader }) {
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        if (hidePreloader) {
            setOpacity(0);
            setTimeout(() => setOpacity(-1), 500);  // Transition to 'display: none' state
        }
    }, [hidePreloader]);

    return (
        <div
            style={{
                display: opacity === -1 ? 'none' : 'block',  // Prevents any interaction when hidden
                opacity: Math.max(opacity, 0),  // Prevent negative opacity values
                transition: "opacity 0.5s ease-in-out",
                pointerEvents: 'none',
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 9999,
                background: `url(${internalPageBackground}) center center no-repeat`,
                backgroundSize: 'cover',
            }}
        >
            <img
                src={preloaderGif}
                alt="Loading..."
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "250px",
                }}
            />
        </div>
    );
}

export default Preloader;
