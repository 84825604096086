// helpers/mapHelpers.js
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import { isMobile } from 'react-device-detect';
import toast_bg from '../../../images/box-logo.png';

let toastId = null;

const showOrientationToast = () => {
    toastId = Toastify({
        text: 'For proper user experience, please use landscape orientation.',
        duration: -1,
        close: false,
        gravity: 'top', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        avatar: toast_bg,
        className: "toast-message",
        offset: {
            x: 50, // horizontal axis - can be a number or a string indicating unity. eg: '2em'
            y: 0 // vertical axis - can be a number or a string indicating unity. eg: '2em'
        },
        style: {
            animation: "fadeIn 1s",
            fontWeight: 700,
            color: 'white',
            width: '80%',
            background: `url(${toast_bg}) center / cover no-repeat`
        },
        onClose: () => { toastId = null; }
    }).showToast();
};

export const handleOrientationChange = (setOrientation, isMenuOrPopupOpen, loading) => {
    const dismissed = localStorage.getItem('orientationWarningDismissed') === 'true';
    const currentOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
    setOrientation(currentOrientation);

    const existingToast = document.querySelector('.toast-message');

    if ( loading!== true && isMobile && currentOrientation === 'portrait' && !dismissed && !isMenuOrPopupOpen) {
        if (!existingToast) {
            showOrientationToast();
        }
    } else if (existingToast) {
        existingToast.remove();
        toastId = null;
    }
};

export const initialOrientationCheck = (setOrientation, isMenuOrPopupOpen, loading) => {
    const dismissed = localStorage.getItem('orientationWarningDismissed') === 'true';
    const currentOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
    setOrientation(currentOrientation);
    const existingToast = document.querySelector('.toast-message');

    if (loading!== true && isMobile && currentOrientation === 'portrait' && !dismissed && !isMenuOrPopupOpen) {
        if (!existingToast) {
            showOrientationToast();
        }
    }
};

export const preloadMedia = (url) => {
    return new Promise((resolve, reject) => {
        const media = url.includes(".mp4") || url.includes(".webm") ? document.createElement("video") : new Image();
        media.onload = () => {
            resolve(media);
        };
        media.onerror = () => {
            console.error("Error loading media:", url);
            reject(new Error(`Failed to load media from ${url}`));
        };
        media.src = url;  // Initiate loading
        if (url.includes(".mp4")) {
            media.load();
        }
    });
};

export const preloadAllMedia = async (mediaUrls) => {
    try {
        await Promise.all(mediaUrls.map(url => preloadMedia(url)));
    } catch (error) {
        console.error("Error preloading resources:", error);
    }
};

export const isImage = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
    return imageExtensions.includes(url.substring(url.lastIndexOf(".")).toLowerCase());
};

export const iOS = () => {
    return [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
    ].includes(navigator.platform);
};