import L from "leaflet";
import btn0 from "../images/0.png";
import btn1 from "../images/1.png";
import btn2 from "../images/2.png";
import btn3 from "../images/3.png";
import btn4 from "../images/4.png";
import btn5 from "../images/5.png";
import btn6 from "../images/6.png";
import btn7 from "../images/7.png";
import btn8 from "../images/8.png";

import internal1 from "../images/internalpage-1.webp";
import internal2 from "../images/internalpage-2.webp";
import internal3 from "../images/internalpage-3.webp";
import internal4 from "../images/internalpage-4.webp";
import internal4Video from "../images/internal4.webm";
import internal5 from "../images/internalpage-5.webp";
import internal5Video from "../images/internal5.webm";
import internal6 from "../images/internalpage-6.webp";
import internal7 from "../images/internalpage-7.webp";

import warrior from "../images/warrior.webp";
import mage from "../images/mage.webp";
import gif from "../images/concept-bg.gif";

const iconSize = [310, 104];

export const internalData0 = {
  icon: L.icon({
    iconUrl: btn0,
    iconSize: [148, 47],
    iconAnchor: [16, 16],
  }),
  iconPos: [52, -650],
  modal: {
    title: "We Have",
    title2: "",
    desc: (
      <>
        <div className={"container-with-padding"}>
          <iframe
            width="60%"
            height="400"
            src="https://www.youtube.com/embed/AxvpZLIL96k?si=DgI3WtcjsRjRKtQf"
            title=""
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{ display: "block", margin: "0 auto" }}>
          </iframe>
          <br />{" "}
          <p className="paragraph">
            A wise elder sits in the tavern by the old fireplace, where the fire quietly crackles, and the air is filled with the aroma of ancient spells and adventures. His eyes gleam with anticipation as he notices a young wanderer stepping through the threshold. The elder waves his hand, inviting the newcomer to the fire, and begins his tale:
          </p>{" "}
          <p className="paragraph straight">
            “Oh, my dear wanderer, you have come to me seeking knowledge that will open new horizons for you. Yes, I see a thirst for adventure and an irresistible urge for knowledge in your eyes. Allow me to tell you about the great marvel that is about to unfold, about a world hidden from many’s eyes but soon revealed to the chosen ones. Listen carefully, for I shall tell you about QTEM, a magical game that will change our perception of mastery and fate.”
          </p>{" "}
          <p className="paragraph">
            The elder pauses to ensure the wanderer is paying attention and continues:
          </p>{" "}
          <p className="paragraph straight">
            “In this world, where magic intertwines with skill, you will discover mechanics created by ancient wizards. Two primary forces govern the fate of heroes: the mastery of quick events and the strategic art of skillfully dodging enemy attacks. Only true masters can master these arts, for they require not only intellect and agility but also a deep understanding of the nuances of magic and strategy. Even in a fleeting battle, there is a precisely calibrated strategy, where every strike is but a moment in which all is known five steps ahead.”
          </p>{" "}

          <img src={warrior} alt="warrior" />
          <br /> <br />{" "}
          <img src={mage} alt="mage" />

          <p className="paragraph">
            He takes a sip from his ancient cup and continues:
          </p>{" "}
          <p className="paragraph straight">
            “As you journey through levels divided into ten rooms, you will face numerous opponents, some of whom will challenge your skills and become a true test. You will find your refuge in safe zones, dodging the cunning attacks of enemies and using your weapons and magic to prevail in these trials.”
          </p>{" "}

          <img src={gif} alt={"concept-gif"} />

          <p className="paragraph">
            The elder stands up and slowly walks around the fire, his voice confident and deep:
          </p>{" "}
          <p className="paragraph straight">
            “With each enemy, with each level, you will grow, mastering new skills and transforming your weaponry into wondrous artifacts of power. In this epic story, new heroes, new abilities, new lands, and new challenges await you.”
          </p>{" "}

          <p className="paragraph">
            He stops, looking into the wanderer’s eyes:
          </p>{" "}
          <p className="paragraph straight">
            “So sit down, wanderer, and let this story captivate your heart. Adventures in QTEM await you, where every action opens the door to a new world full of magic and heroism. Are you ready to embark on this path, wanderer? For your adventure is just beginning!”
          </p>{" "}

          <br /> <br />{" "}
        </div>
      </>
    ),
  },
};

export const GameData = [
  {
    icon: L.icon({
      iconUrl: btn1,
      iconSize: iconSize,
      iconAnchor: [16, 16],
    }),
    iconPos: [83, -500],
    modal: {
      bg: [internal1],
      title: "So, It Began",
      title2: "First Content Expansion",
      desc: (
        <>
          <span className="text-diferent">New Challenges</span>
          <br />{" "}
          <p className="paragraph">
            <li>Brace yourself and unleash your skills across a whopping 100 new game levels that will push your abilities to the limit! </li>
          </p>{" "}
          <p className="paragraph">
            <li>Dive deeper into a carefully created world, filled with even more challenges and diverse landscapes, from castles to ruins, tombs, abandoned cities and many more exciting scenarios, each one bringing a unique set of obstacles and of course, loot!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">The Magnificent 7</span>
          <br />{" "}
          <p className="paragraph">
            <li>Seven new exciting heroes to try: <b>Phalanx, Necromancer, Druid, Elementalist, Assassin, Archer</b> and <b>Summoner</b>, each one with their unique gameplay style! Check them out:</li>
          </p>{" "}
          <p className="paragraph">
            <li><b>Phalanx</b>: Do you like being an absolute unit with tons and tons of defense? Phalanx is the right choice! An impenetrable defense with tons of HP!</li>
          </p>{" "}
          <p className="paragraph">
            <li><b>Necromancer</b>: “Just because you’re dead doesn’t mean it’s the end! Rise from your grave, minions!”, said the Necromancer, before unleashing the forces of the undead into the enemy team.</li>
          </p>{" "}
          <p className="paragraph">
            <li><b>Druid</b>: Transform into a big bear and ravage the enemy defenses! The Druid can get through enemy defenses with ease while still being able to hold on his own! An absolute powerhouse!</li>
          </p>{" "}
          <p className="paragraph">
            <li><b>Elementalist</b>: Master the elements of nature! Water, Earth, Fire, Air and Aether - Depending on how good you are, the effects can be devastating!</li>
          </p>{" "}
          <p className="paragraph">
            <li><b>Assassin</b>: Quickly assassinate your enemies without them even noticing you, but careful, the Assassin can also die very quickly!</li>
          </p>{" "}
          <p className="paragraph">
            <li><b>Archer</b>: To spam or to not spam! Hold your bow steady and you’ll have high accuracy and high damage, but your enemies might hit you if you take too long! Spam your arrows and your enemies will barely have time to attack you, but you will do significantly less damage and not all arrows will hit!</li>
          </p>{" "}
          <p className="paragraph">
            <li><b>Summoner</b>: Summon the help of humans, beasts and even demons to help you in your battles. Make a binding with them and increase their power, but be careful! If they die, you die as well!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Ascend To New Heights In The Endless Tower</span>
          <br />{" "}
          <p className="paragraph">
            <li>Step into the epicenter of excitement and prepare to defy the impossible.</li>
          </p>{" "}
          <p className="paragraph">
            <li>The Endless Tower is not just a collection of levels; it's a dynamic and ever-evolving journey that will keep you engaged for hours on end.</li>
          </p>{" "}
          <p className="paragraph">
            <li>No levels are equal, ensuring that you'll need to strategize, adapt, and hone your skills to emerge victorious if you want to keep climbing!.</li>
          </p>{" "}
          <p className="paragraph">
            <li>As you ascend the towering heights of the game, the difficulty will intensify, providing a constant thrill for both casual players and seasoned veterans. Some floors have unique legendaries that can’t be found outside of the tower as well, and believe us when we say you WILL want that gear later on!</li>
          </p>{" "}
          <p className="paragraph">
            <li>There’s a new environment every 250 levels, which means you have to change your strategy to keep climbing or you won’t go far!</li>
          </p>{" "}
          <p className="paragraph">
            <li>Be careful to not die as well. If you do, you’re falling all the way down to Level 0. You can build camps that act as checkpoints, but to do that, you need time and resources!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Hardcore Mode</span>
          <br />{" "}
          <p className="paragraph">
            <li><b>Boring trends?! Absolutely not!</b> Here’s our recipe on how to cook a hardcore punch: We take a unique mechanic that you already love, and then we put a pinch of a new mechanic.</li>
          </p>{" "}
          <p className="paragraph">
            <li>“That should be fun!” <b>Ohohoho, it’s not >:)</b> This mode isn’t about fun, it’s about sorrow and despair! You better have a good memory, because it WILL be tested! Blame the devs for being cruel and evil just for laughs.</li>
          </p>{" "}
          <p className="paragraph">
            <li><b>Rise of the Machines</b>: So, you think you’re pretty good against the enemies don’t you? Let’s see what you can do when the enemies adapt and overcome your actions. With AI being implemented, they become super more challenging!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">The Shaman</span>
          <br />{" "}
          <p className="paragraph">
            <li><b>Zephyr The Shaman </b> is a completely different hero, because of his unique skill set and mechanics.</li>
          </p>{" "}
          <p className="paragraph">
            <li>Having trouble with random QTEs? Let your <b>Spectral Ally </b> help you and automatically pass it for you!</li>
          </p>{" "}
          <p className="paragraph">
            <li>Getting hit too much? Use <b>Spiritual Resonance </b>and let the spirits show you where the enemy is going to attack, enabling you to prepare in advance.</li>
          </p>{" "}
          <p className="paragraph">
            <li>Died right before killing the boss? Hopefully you used <b>Ethereal Vengeance </b>then, that way your corporeal form might be able to finish the enemy and save you from doom!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">The Saga Continues</span>
          <br />{" "}
          <p className="paragraph">
            <li>Who are the heroes?</li>
          </p>{" "}
          <p className="paragraph">
            <li>Who is the villain?</li>
          </p>{" "}
          <p className="paragraph">
            <li>What is the meaning of those battles?</li>
          </p>{" "}
          <p className="paragraph">
            <li>What is the absurdly big banana in the background of the 4th level?</li>
          </p>{" "}
          <p className="paragraph">
            <li>All of that will be answered as our story unfolds, complete with lore, backstory and enemy descriptions!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Closed Beta</span>
          <br />{" "}
          <p className="paragraph">
            <li>Beta invitations for everyone that supported us to get through the first milestone!</li>
          </p>{" "}
          <p className="paragraph">
            <li>Exclusive in-game cosmetics!</li>
          </p>{" "}
          <p className="paragraph">
            <li>Reach high enough in the Endless Tower before the end of the Beta to receive special rewards!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Public Release</span>
          <br />{" "}
          <p className="paragraph">
            <li>Don’t worry, we won’t make you wait for a long time. Everyone will be able to taste our game :)</li>
          </p>{" "}
          <br /> <br /> <br /> <br />{" "}
        </>
      ),
    },
  },
  {
    icon: L.icon({
      iconUrl: btn2,
      iconSize: iconSize,
      iconAnchor: [16, 16],
    }),
    iconPos: [22, -440],
    modal: {
      bg: [internal2],
      title: "Forgotten Knowledge",
      title2: "New Animations",
      desc: (
        <>
          <span className="text-diferent">Enemy Animations</span>
          <br />{" "}
          <p className="paragraph">
            <li>New attack animations. A lot of animations. Hundreds of new animations for each enemy, which in turn, means a new mechanic ;)</li>
          </p>{" "}
          <p className="paragraph">
            <li>New death animations.</li>
          </p>{" "}
          <p className="paragraph">
            <li>New taunt animations if you fail your attacks! The audacity!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Player Animations</span>
          <br />{" "}
          <p className="paragraph">
            <li>New attack animations.</li>
          </p>{" "}
          <p className="paragraph">
            <li>New death animations.</li>
          </p>{" "}
          <p className="paragraph">
            <li>New poses, depending on the gear you’re using.</li>
          </p>{" "}
          <p className="paragraph">
            <li>New idle animations.</li>
          </p>{" "}
          <br /> <br /> <br /> <br />{" "}
        </>
      ),
    },
  },
  {
    icon: L.icon({
      iconUrl: btn3,
      iconSize: iconSize,
      iconAnchor: [16, 16],
    }),
    iconPos: [-70, -340],
    modal: {
      bg: [internal3],
      title: "Buried Temple",
      title2: "Second Content Expansion",
      desc: (
        <>
          <span className="text-diferent">Because Everything Is Better With Friends</span>
          <br />{" "}
          <p className="paragraph">
            <li>Who doesn’t love playing and conquering new challenges with their friends? <b>Multiplayer</b> gameplay added! Along with <b>Leaderboards</b> and a <b>Social System!</b></li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Clans</span>
          <br />{" "}
          <p className="paragraph">
            <li>“Yeah yeah another clan system, nothing new…” <b>Except it’s not! </b> With Clans, comes Clan Castles! And it’s not just a picture of a castle in the map, you use it to send troops, get loot, share spoils of war, it’s work for everyone in your clan!</li>
          </p>{" "}
          <p className="paragraph">
            <li><b>It’s also a raid.</b> What, you think you can just leave a CASTLE unguarded and no one will try to take it? Unless the whole clan does their job to defend the castle, everyone will get wiped and that might have dire consequences!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Look At The Size Of That Thing</span>
          <br />{" "}
          <p className="paragraph">
            <li>That’s what our heroes said when they saw the new gigantic and legendary enemies that can only be defeated by multiple players at once on a <b>Raid! </b>Coordinate your efforts to earn legendary loot on this new system!</li>
          </p>{" "}
          <p className="paragraph">
            <li>As expected from Raids, everyone has a role - Unique mechanics and gameplay where each raid member must do their job correctly, like healing and buffing your allies, dealing damage, debuffing the enemies and tanking the damage. Strategy is king!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Pets Join The Fray</span>
          <br />{" "}
          <p className="paragraph">
            <li>It can be lonely out there in the battlefields. That’s why we’ll have <b>Pets!</b></li>
          </p>{" "}
          <p className="paragraph">
            <li>Level up your loyal companion and get new buffs!</li>
          </p>{" "}
          <p className="paragraph">
            <li>Your pets can’t look all basic and unprotected. You can also put outfits on them!</li>
          </p>{" "}
          <p className="paragraph">
            <li>There’s a lot of them. Gotta have ‘em all!</li>
          </p>{" "}
          <br /> <br /> <br /> <br />{" "}
        </>
      ),
    },
  },
  {
    icon: L.icon({
      iconUrl: btn4,
      iconSize: iconSize,
      iconAnchor: [16, 16],
    }),
    iconPos: [16, -50],
    modal: {
      bg: [internal4],
      animationBg: internal4Video,
      title: "First Great Rift",
      title2: "The Eye Candy Upgrade",
      desc: (
        <>
          <span className="text-diferent">Complete Design Overhaul - Characters</span>
          <br />{" "}
          <p className="paragraph">
            <li>Our heroes are the main focus of our story, so they could use some upgrades in their wardrobes with some shiny new graphics!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Complete Design Overhaul - Levels</span>
          <br />{" "}
          <p className="paragraph">
            <li>The world itself deserves some love as well! Amazing new graphics for all levels so they look as good as our heroes! And yes, the absurdly big banana in the background of the 4th level STAYS!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Complete Overhaul - Items And Modularity</span>
          <br />{" "}
          <p className="paragraph">
            <li>Lastly, the items! There’s… plenty of them. Like, a lot. Regardless, they will all have a stunning new look, even the ones you don’t use!</li>
          </p>{" "}
          <br /> <br /> <br /> <br />{" "}
        </>
      ),
    },
  },
  {
    icon: L.icon({
      iconUrl: btn5,
      iconSize: iconSize,
      iconAnchor: [16, 16],
    }),
    iconPos: [-80, 200],
    modal: {
      bg: [internal5],
      animationBg: internal5Video,
      title: "New Aurora City",
      title2: "The Eye Candy Upgrade Part 2: VFX",
      desc: (
        <>
          <span className="text-diferent">New Visual Effects - Characters</span>
          <br />{" "}
          <p className="paragraph">
            <li>New VFX for attacks.</li>
          </p>{" "}
          <p className="paragraph">
            <li>New VFX for abilities.</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">New Visual Effects - World</span>
          <br />{" "}
          <p className="paragraph">
            <li>New VFX on the environment.</li>
          </p>{" "}
          <p className="paragraph">
            <li>New VFX for abilities.</li>
          </p>{" "}
          <br /> <br /> <br /> <br />{" "}
        </>
      ),
    },
  },
  {
    icon: L.icon({
      iconUrl: btn6,
      iconSize: iconSize,
      iconAnchor: [16, 16],
    }),
    iconPos: [68, 300],
    modal: {
      bg: [internal6],
      title: "Ironfang Mountain",
      title2: "Third Content Expansion",
      desc: (
        <>
          <span className="text-diferent">Oh Lord, Even More Trouble</span>
          <br />{" "}
          <p className="paragraph">
            <li>New enemies. Some of them are funny, some of them are cool, and some of them are downright EVIL.</li>
          </p>{" "}
          <p className="paragraph">
            <li>Unique mechanics with the evil ones. You’re gonna be so glad when you defeat them. Just don’t use bad words after doing it.</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Professions</span>
          <br />{" "}
          <p className="paragraph">
            <li>Now you can gain professions (6 to be exact), helping your clan and yourself with different gameplay!</li>
          </p>{" "}
          <p className="paragraph">
            <li>Each profession must be leveled separately.</li>
          </p>{" "}
          <p className="paragraph">
            <li>Swap freely between them, no strings attached!</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Let’s Quest Together</span>
          <br />{" "}
          <p className="paragraph">
            <li>Introduction of quests, for even more rewards and challenges!</li>
          </p>{" "}
          <p className="paragraph">
            <li>Complete with achievements!</li>
          </p>{" "}
          <p className="paragraph">
            <li>No dailies or weeklies, we know how much you hate those ;)</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">It’s Good, But It Could Be Better</span>
          <br />{" "}
          <p className="paragraph">
            <li>Complete overhaul of the <b>user interface.</b></li>
          </p>{" "}
          <p className="paragraph">
            <li>Much more complete and straight to the point!</li>
          </p>{" "}
          <p className="paragraph">
            <li>Choose between different themes.</li>
          </p>{" "}
          <br /> <br /> <br /> <br />{" "}
        </>
      ),
    },
  },
  {
    icon: L.icon({
      iconUrl: btn7,
      iconSize: iconSize,
      iconAnchor: [16, 16],
    }),
    iconPos: [-65, 460],
    modal: {
      bg: [internal7],
      title: "Island Of Singing Streams",
      title2: "Music And Sound Upgrade",
      desc: (
        <>
          <span className="text-diferent">Music For Our Heroes</span>
          <br />{" "}
          <p className="paragraph">
            <li><b>Meloria The Bard </b>takes center stage!</li>
          </p>{" "}
          <p className="paragraph">
            <li>Harness the harmonious blend of music and combat with his new magical device which he gave the name of “guitar”. Everyone even started calling her… Guitar Hero?</li>
          </p>{" "}     
          <p className="paragraph">
            <li>Unleash a powerful sonic wave that disorients and damages enemies, sometimes even making them hit each other with <b>Sonic Crescendo!</b></li>
          </p>{" "}
          <p className="paragraph">
            <li>Remove debuffs with <b>Rhapsody of Renewal</b>, and follow up with <b>Bardic Serenade</b> to temporarily pacify your enemies, allowing you to heal with <b>Chord of Healing!</b></li>
          </p>{" "}
          <p className="paragraph">
            <li>Lastly, with <b>Fire Eyes</b>, you get a new mechanic, which is not a hard one, but you shouldn’t try it on Hardcore Mode ;)</li>
          </p>{" "}
          <p className="paragraph">
            <li>Although she’s loving her “guitar”, she might get bored of it, so she’ll get even more instruments! Four of them should be enough, right? RIGHT?</li>
          </p>{" "}
          <br /> <br />{" "}


          <span className="text-diferent">Music And Sound Updates</span>
          <br />{" "}
          <p className="paragraph">
            <li>All sounds and the soundtrack will be improved! More choirs! More epicness!</li>
          </p>{" "}
          <p className="paragraph">
            <li>We might even release an official OST, who knows!</li>
          </p>{" "}
          <br /> <br /> <br /> <br />{" "}
        </>
      ),
    },
  },
  {
    icon: L.icon({
      iconUrl: btn8,
      iconSize: iconSize,
      iconAnchor: [16, 16],
    }),
    iconPos: [12, 560],
    modal: {
      title: "Infinity’s End Here",
      title2: "Future Content Updates",
      desc: (
        <>
          <div className={"container-with-padding"}>
            <span className="text-diferent">The future is bright. Join our social media to get updates!</span>
            <br />{" "}
          </div>
        </>
      ),
    },
  },
];