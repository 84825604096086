import styles from './Item.module.css';

const Item = ({ children }) => {
  return (
    <div className={styles["menu-item"]}>
      {children}
    </div>
  )
}

export default Item