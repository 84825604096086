import React, { useState, useEffect } from 'react';
import './StikyHeader.css'
import { Link, animateScroll as scroll } from "react-scroll"; // Import react-scroll

import arrow from '../../images/arrow-header.png'
import twitter from '../../images/x-h.svg'
import instagram from '../../images/ig-h.svg'
import facebook from '../../images/f-h.svg'
import youtube from '../../images/y-h.svg'
import linkedin from '../../images/i-h.svg'
import burgerIcon from '../../images/burger-icon.svg'
import closeIcon from '../../images/close.svg'

const StickyHeader = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for menu visibility

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle menu visibility
    };

    return (
        <div className={`sticky-header ${isMenuOpen && windowWidth <= 850  ? 'active' : ''}`}>
            {windowWidth <= 850 ? <NewMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu}/> : <OldMenu/>}
        </div>
    );
};

const OldMenu = () => {
    return (
        <header className="header">
            <ul className="menu">
                <li><img src={arrow} alt="arrow-1"/> <Link to="subscribe" smooth={true} duration={1000}>Subscribe</Link></li>
                <li><img src={arrow} alt="arrow-2"/> <a href="/road-map">Road Map</a></li>
                <li><img src={arrow} alt="arrow-3"/> <a href="https://inoutbox.games/" target="_blank">Inoutbox.games</a></li>
                <li><img src={arrow} alt="arrow-4"/> <a href="https://inoutbox.games/privacy-policy/" target="_blank">Privacy</a></li>
                <li>
                    <a href="https://www.youtube.com/@inoutboxgames" target="_blank"><img src={youtube} alt="youtube"/></a>
                    <a href="https://www.linkedin.com/company/inoutboxgames/" target="_blank"><img src={linkedin} alt="linkedin"/></a>
                    <a href="https://www.facebook.com/inoutboxgames/" target="_blank"><img src={facebook} alt="facebook"/></a>
                    <a href="https://www.instagram.com/inoutboxgames/" target="_blank"><img src={instagram} alt="instagram"/></a>
                    <a href="https://twitter.com/inoutboxgames/" target="_blank"><img src={twitter} alt="twitter"/></a>
                </li>
            </ul>
        </header>
    );
};

const NewMenu = ({ isMenuOpen, toggleMenu }) => {
    return (
        <header className="header">
            <ul className="new-menu">
                <li className="burger-menu" onClick={toggleMenu}>
                    <img src={isMenuOpen ? closeIcon : burgerIcon} alt="burger-menu"/> {/* Используйте closeIcon или burgerIcon в зависимости от состояния меню */}
                </li>
                <li className="social-media">
                    <a href="https://www.youtube.com/@inoutboxgames" target="_blank"><img src={youtube} alt="youtube"/></a>
                    <a href="https://www.linkedin.com/company/inoutboxgames/" target="_blank"><img src={linkedin} alt="linkedin"/></a>
                    <a href="https://www.facebook.com/inoutboxgames/" target="_blank"><img src={facebook} alt="facebook"/></a>
                    <a href="https://www.instagram.com/inoutboxgames/" target="_blank"><img src={instagram} alt="instagram"/></a>
                    <a href="https://twitter.com/inoutboxgames/" target="_blank"><img src={twitter} alt="twitter"/></a>
                </li>
            </ul>
            {isMenuOpen && (
                <>

                    <div className="popup-menu">

                        <div className="menu-content">
                            <li><img src={arrow} alt="arrow-1"/> <Link to="subscribe" smooth={true} duration={1000}>Subscribe</Link></li>
                            <li><img src={arrow} alt="arrow-2"/> <a href="/road-map">Road Map</a></li>
                            <li><img src={arrow} alt="arrow-3"/> <a href="https://inoutbox.games/" target="_blank">Inoutbox.games</a></li>
                            <li><img src={arrow} alt="arrow-4"/> <a href="https://inoutbox.games/privacy-policy/" target="_blank">Privacy</a></li>
                        </div>
                    </div>
                </>
            )}
        </header>
    );
};


export default StickyHeader;
