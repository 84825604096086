import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MapaCustomizado from './pages/RoadMap/RoadMap';
import HomePage from './pages/HomePage/HomePage';
import Preloader from './components/Preloader/Preloader';

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleLoad = () => {
            setLoading(false);
            localStorage.setItem('imagesLoaded', true); // Save flag to localStorage once all images are loaded
        };

        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    return (
        <Router>
            <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/road-map" element={<MapaCustomizado loading={loading} />} />
                </Routes>
            </div>
            <Preloader hidePreloader={!loading} />
        </Router>
    );
}

export default App;
