// Import necessary modules and components
import React, { useState, useEffect } from "react";
import "./HomePage.css"; // Import CSS file
import { LazyLoadImage } from 'react-lazy-load-image-component'; // Lazy load images
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import blur effect CSS
import ReactPixel from 'react-facebook-pixel'; // Import React Pixel
import StickyHeader from '../../components/StikyHeader/StikyHeader';

import MailchimpSubscribe from "react-mailchimp-subscribe"; // Mailchimp subscription component
import hero from "../../images/home-hero.webp"; // Import image assets
import lore from "../../images/home-lore1.webp";
import lore_bottom from "../../images/home-lore2.webp";
import title from "../../images/form-title.webp";
import title_black from "../../images/form-title-black.webp";
import left_img from "../../images/left-text.webp";
import left_img_black from "../../images/left-text-black.webp";
import right_img from "../../images/right-text.webp";
import right_img_black from "../../images/right-text-black.webp";
import gameplay from "../../images/gameplay.webp";
import gameplay1 from "../../images/gameplay1.webp";
import gameplay2 from "../../images/Gameplay-2.webp";
import gameplay2_2 from "../../images/Gameplay 2.webp";
import heroes from "../../images/Heroes.webp";
import enemies from "../../images/Enemies.webp";
import enemies1 from "../../images/Enemies-1.webp";
import elite from "../../images/elite.webp";
import boss from "../../images/boss.webp";
import team from "../../images/team.webp";
import rewards from "../../images/REWARDS.webp";

// URL for Mailchimp subscription
const url = "//games.us9.list-manage.com/subscribe/post?u=05d595e21d9b62bf55d17d647&amp;id=b4657e156a&amp;f_id=002720e1f0";

// Custom Form Component for Mailchimp subscription
const CustomForm = ({ status, message, onValidated }) => {
    const [errorMessage, setErrorMessage] = useState(""); // State for error message
    const [responseMessage, setResponseMessage] = useState(""); // State for response message
    const [inputS, setInputS] = useState('example@mail.com');

    let email; // Reference to email input


    // Function to handle input change
    const handleInputChange = () => {
        setErrorMessage(""); // Clear error message when user starts typing
        setResponseMessage(""); // Clear response message when user starts typing

        const mailchimpResponse = document.querySelector('.mailchimp-response');
        if (mailchimpResponse) {
            mailchimpResponse.textContent = '';
        }
    };

    const handleOnFocus = () => {
        setInputS('');
    };

    // Function to submit form
    const submit = () => {
        if (!email || !email.value) {
            setErrorMessage("Please enter your email address.");
            return;
        }

        if (email.value.indexOf("@") === -1) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        setErrorMessage(""); // Clear any previous error message
        setResponseMessage("Sending..."); // Display sending message
        onValidated({
            EMAIL: email.value
        });

        // Track successful lead submission with Facebook Pixel
        ReactPixel.track('Lead', { email: email.value });
    };

    return (
        <div>
            {/* Email input field */}
            <input
                ref={node => (email = node)}
                type="email"
                placeholder={inputS}
                onFocus={handleOnFocus}
                onChange={handleInputChange} // Add onChange event listener
            />
            {/* Subscribe button */}
            <button onClick={submit} type="button">
                Subscribe
            </button>
            {/* Error message */}
            {errorMessage && (
                <div style={{ color: "red" }} className="mailchimp-response">
                    {errorMessage}
                </div>
            )}
            {/* Sending message */}
            {status === "sending" && (
                <div className="mailchimp-response" style={{ color: "#05CE78" }}>
                    {responseMessage}
                </div>
            )}
            {/* Error message from Mailchimp */}
            {status === "error" && (
                <div
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                    className="mailchimp-response"
                />
            )}
            {/* Success message from Mailchimp */}
            {status === "success" && (
                <div
                    style={{ color: "green" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                    className="mailchimp-response"
                />
            )}
        </div>
    );
};

// Homepage Component
function HomePage() {
    useEffect(() => {
        // Initialize Facebook Pixel
        ReactPixel.init('288887837204044');
    }, []);

    return (
        <div>
            {/* StickyHeader component */}
            <StickyHeader/>

            <div className="homepage">
                {/* YouTube video */}
                <div className="video-container">
                    <div className="video-wrapper">
                        <iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/AxvpZLIL96k?autoplay=1&rel=0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
                {/* Hero image */}
                <LazyLoadImage src={hero} alt="hero-image" effect="blur"/>
                {/* Lore image */}
                <LazyLoadImage src={lore} alt="lore-image" effect="blur"/>
                {/* Subscribe section */}
                <div className="subscribe-wrapper" id="subscribe">
                    <div className="subscribe-block">
                        {/* Title image */}
                        <LazyLoadImage src={title} alt="lore-image" className="title-image" effect="blur"/>
                        <div className="mail-form">
                            {/* Left image */}
                            <LazyLoadImage src={left_img} alt="left" effect="blur"/>
                            <div className="mailchimp-form">
                                {/* Mailchimp subscription component */}
                                <MailchimpSubscribe
                                    url={url}
                                    render={({subscribe, status, message}) => (
                                        <CustomForm
                                            status={status}
                                            message={message}
                                            onValidated={formData => subscribe(formData)}
                                        />
                                    )}
                                />

                                
                            </div>
                            {/* Right image */}
                            <LazyLoadImage src={right_img} alt="right" effect="blur"/>
                        </div>
                    <span className="subscribe-info-text">Subscribe for a chance to play early builds of the game, pledge on Kickstarter for exclusive rewards!</span>
                    </div>

                </div>
                {/* Additional images */}
                <LazyLoadImage src={lore_bottom} alt="lore_bottom" loading="lazy" effect="blur"/>
                <LazyLoadImage src={gameplay} alt="gameplay" loading="lazy" effect="blur"/>
                <LazyLoadImage src={gameplay1} alt="gameplay1" loading="lazy" effect="blur"/>
                <LazyLoadImage src={gameplay2} alt="gameplay2" loading="lazy" effect="blur"/>
                <LazyLoadImage src={gameplay2_2} alt="gameplay2_2" loading="lazy" effect="blur"/>
                {/*<LazyLoadImage src={heroes_title} alt="heroes_title" loading="lazy" effect="blur"/>*/}
                {/* Subscribe section */}
                <div className="subscribe-wrapper bottom">
                    <div className="subscribe-block">
                        {/* Title image */}
                        <LazyLoadImage src={title_black} alt="lore-image" className="title-image" effect="blur"/>
                        <div className="mail-form">
                            {/* Left image */}
                            <LazyLoadImage src={left_img_black} alt="left" effect="blur"/>
                            <div className="mailchimp-form">
                                {/* Mailchimp subscription component */}
                                <MailchimpSubscribe
                                    url={url}
                                    render={({subscribe, status, message}) => (
                                        <CustomForm
                                            status={status}
                                            message={message}
                                            onValidated={formData => subscribe(formData)}
                                        />
                                    )}
                                />
                            </div>
                            {/* Right image */}
                            <LazyLoadImage src={right_img_black} alt="right" effect="blur"/>
                        </div>
                        <span className="subscribe-info-text subscribe-info-text--black">Subscribe for a chance to play early builds of the game, pledge on Kickstarter for exclusive rewards!</span>
                    </div>
                </div>
                <LazyLoadImage src={heroes} alt="heroes" loading="lazy" effect="blur"/>
                <LazyLoadImage src={enemies} alt="enemies" loading="lazy" effect="blur"/>
                <LazyLoadImage src={enemies1} alt="enemies1" loading="lazy" effect="blur"/>
                <LazyLoadImage src={elite} alt="elite" loading="lazy" effect="blur"/>
                <LazyLoadImage src={boss} alt="boss" loading="lazy" effect="blur"/>
                {/* Subscribe section */}
                <div className="subscribe-wrapper bottom">
                    <div className="subscribe-block">
                        {/* Title image */}
                        <LazyLoadImage src={title_black} alt="lore-image" className="title-image" effect="blur"/>
                        <div className="mail-form">
                            {/* Left image */}
                            <LazyLoadImage src={left_img_black} alt="left" effect="blur"/>
                            <div className="mailchimp-form">
                                {/* Mailchimp subscription component */}
                                <MailchimpSubscribe
                                    url={url}
                                    render={({subscribe, status, message}) => (
                                        <CustomForm
                                            status={status}
                                            message={message}
                                            onValidated={formData => subscribe(formData)}
                                        />
                                    )}
                                />
                            </div>
                            {/* Right image */}
                            <LazyLoadImage src={right_img_black} alt="right" effect="blur"/>
                        </div>
                        <span className="subscribe-info-text subscribe-info-text--black">Subscribe for a chance to play early builds of the game, pledge on Kickstarter for exclusive rewards!</span>
                    </div>
                </div>
                <LazyLoadImage src={rewards} alt="rewards" loading="lazy" effect="blur"/>
                <LazyLoadImage src={team} alt="team" loading="lazy" effect="blur"/>
            </div>
        </div>

    );
}

export default HomePage;