//MapaCustomizado
import React, { useEffect, useState, useRef } from "react";
import { MapContainer, ImageOverlay, Marker, useMapEvent } from "react-leaflet";
import ReactModal from "react-modal";
import ReactGA from "react-ga";
import "leaflet/dist/leaflet.css";
import "react-modern-drawer/dist/index.css";
import "../../map.css";
import FixedButtons from "../../components/fixedButtons/FixedButtons";

import map from "../../images/fullmap.webp";
import internalpageBackground from "../../images/internalpage-background.webp";
import internalpageTopbanner from "../../images/internalpage-top-banner.png";

import { internalData0, GameData } from "../../data/markers";

import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube.svg";

import "../../fonts/Amnestia.otf";
import "../../fonts/Amnestia.ttf";
import NewsletterPopup from "../../components/NewsLetterPopup/NewsLetterPopup";
import Menu from "../../components/menu/Menu";
import { LINKS } from "../../data/links";

import {
    preloadAllMedia,
    handleOrientationChange,
    initialOrientationCheck,
    iOS
} from './helpers/mapHelpers';

import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

/* global Video */
function SetViewOnClick() {
    const map = useMapEvent("click", (e) => {
        map.setView(e.latlng, map.getZoom(), {
            animate: true,
        });
    });

    return null;
}

function MapaCustomizado({ loading }) {

    let imageUrl = map;
    const isIos = iOS();
    const imageBounds = [[-90, -680], [90, 680]];
    const [backgroundFadeState, setBackgroundFadeState] = useState("");
    const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? "landscape" : "portrait");
    const [conteudoCarregado, setConteudoCarregado] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [modalOpening, setModalOpening] = useState(false);
    const [modalClosing, setModalClosing] = useState(false);
    const [openNewsletter, setOpenNewsletter] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [cachedMedia, setCachedMedia] = useState({});
    const [isMenuOrPopupOpen, setIsMenuOrPopupOpen] = useState(false);

    useEffect(() => {
        initialOrientationCheck(setOrientation, isMenuOrPopupOpen);

        if (!conteudoCarregado) {
            const mediaUrls = [map];
            preloadAllMedia(mediaUrls).then(() => setConteudoCarregado(true));

            const timer = setTimeout(() => {
                setOpenNewsletter(true);
            }, 30000);

            window.addEventListener("resize", () => handleOrientationChange(setOrientation, isMenuOrPopupOpen));
            window.addEventListener("orientationchange", () => handleOrientationChange(setOrientation, isMenuOrPopupOpen));

            return () => {
                window.removeEventListener("resize", () => handleOrientationChange(setOrientation, isMenuOrPopupOpen));
                window.removeEventListener("orientationchange", () => handleOrientationChange(setOrientation, isMenuOrPopupOpen));
                clearTimeout(timer);
            };
        }
    }, [conteudoCarregado, isMenuOrPopupOpen]);

    useEffect(() => {
        handleOrientationChange(setOrientation, isMenuOrPopupOpen, loading);
    }, [isMenuOrPopupOpen]);

    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
        setBackgroundFadeState("active");
        setShowModal(true);
        setModalActive(true);
        setIsMenuOrPopupOpen(true);

        const mediaUrls = [marker.bg, marker.animationBg].filter(Boolean);
        const uncachedMedia = mediaUrls.filter(url => !cachedMedia[url]);

        if (uncachedMedia.length > 0) {
            preloadAllMedia(uncachedMedia).then(() => {
                setCachedMedia(prev => ({
                    ...prev,
                    ...Object.fromEntries(uncachedMedia.map(url => [url, true]))
                }));
            });
        }
    };

    const closeModal = () => {
        setBackgroundFadeState("inactive");
        setModalClosing(true);
        setTimeout(() => {
            setShowModal(false);
            setModalActive(false);
            setModalClosing(false);
            setBackgroundFadeState("");
            setIsMenuOrPopupOpen(false);
        }, 500);
    };

    const dismissToast = () => {
        localStorage.setItem('orientationWarningDismissed', 'true');
        const existingToast = document.querySelector('.toast-message');
        if (existingToast) {
            existingToast.remove();
        }
    };

    return (
        <div className="container-map">
            <Menu onChapter={(i) => handleMarkerClick(i)} setIsMenuOrPopupOpen={setIsMenuOrPopupOpen} />
            <FixedButtons />
            <MapContainer
                center={[100, -500]}
                zoom={1.5}
                minZoom={1.5}
                maxZoom={3}
                className="map"
                maxBounds={[
                    [-90, -680],
                    [90, 680],
                ]}
                maxBoundsViscosity={1.0}
                dragging={!showModal}
                zoomControl={false}
                scrollWheelZoom={!showModal}
                doubleClickZoom={!showModal}
                style={{ pointerEvents: showModal ? "none" : "auto" }}
            >
                <ImageOverlay url={imageUrl} bounds={imageBounds} />
                <Marker
                    position={internalData0.iconPos}
                    icon={internalData0.icon}
                    eventHandlers={{
                        click: () => handleMarkerClick(internalData0.modal),
                    }}
                />
                {GameData.map((item) => (
                    <Marker
                        position={item.iconPos}
                        icon={item.icon}
                        eventHandlers={{ click: () => handleMarkerClick(item.modal) }}
                        key={item.modal.title}
                    >
                    </Marker>
                ))}
                <SetViewOnClick />
            </MapContainer>
            <ReactModal
                isOpen={openNewsletter}
                onRequestClose={() => { setOpenNewsletter(false); setIsMenuOrPopupOpen(false); }}
                style={{
                    overlay: {
                        zIndex: 1000,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    },
                    content: {
                        inset: "auto",
                        background: "#F6F2E3",
                        filter: "drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.10))",
                    },
                }}
            >
                <NewsletterPopup onClose={() => { setOpenNewsletter(false); setIsMenuOrPopupOpen(false); }} />
            </ReactModal>
            {showModal && (
                <div
                    className={`overlay ${modalActive ? "active" : modalClosing ? "inactive" : ""}`}
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div className={`background-fade ${backgroundFadeState}`}></div>

                    <div
                        className={`modal ${modalOpening ? "opening" : ""} ${modalActive ? "active" : ""} ${modalClosing ? "closing" : ""}`}
                    >
                        <div className="image-wrapper">
                            {selectedMarker && (
                                <div style={{ height: "100%" }}>
                                    <img
                                        src={internalpageBackground}
                                        alt="Imagem Modal"
                                        style={{ minWidth: "96vw" }}
                                    />
                                    <div className="modal-image-container">
                                        <img
                                            className="modal-top-banner-image"
                                            src={internalpageTopbanner}
                                            alt="internalpage top banner"
                                        />
                                        {!isIos && selectedMarker.animationBg && (
                                            <video
                                                autoPlay
                                                loop
                                                playsInline
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 0,
                                                    height: "100%",
                                                }}
                                            >
                                                <source
                                                    src={selectedMarker.animationBg}
                                                    type="video/webm"
                                                />
                                                Your browser doesn't support the video element.
                                            </video>
                                        )}
                                        {selectedMarker.bg && (!selectedMarker.animationBg || isIos) && (
                                            <img
                                                className="modal-bg-image"
                                                src={selectedMarker.bg[0]}
                                                alt="Imagem Modal"
                                            />
                                        )}
                                    </div>

                                    <div className="title">
                                        <p>{selectedMarker.title}</p>
                                    </div>

                                    <div
                                        className="modal-content-wrapper"
                                        style={{
                                            width: !selectedMarker.bg && !selectedMarker.animationBg ? "calc(100% - 12vw)" : undefined,
                                        }}
                                    >
                                        <div className="modal-content">
                                            <div className="btn-group social-button-group">
                                                <a
                                                    href={LINKS.facebook}
                                                    className="social-button facebook"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <FacebookIcon />
                                                </a>
                                                <a
                                                    href={LINKS.twitter}
                                                    className="social-button twitter"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <TwitterIcon />
                                                </a>
                                                <a
                                                    href={LINKS.instagram}
                                                    className="social-button instagram"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <InstagramIcon />
                                                </a>
                                                <a
                                                    href={LINKS.youtube}
                                                    className="social-button instagram"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <YoutubeIcon />
                                                </a>
                                            </div>
                                            <div className="text-title">{selectedMarker.title2}</div>

                                            <div className="text-entire">
                                                <div className="text-area-body">
                                                    {selectedMarker.desc}
                                                </div>
                                            </div>
                                            <div className="btn-social">
                                                <div className="btn-group">
                                                    <button
                                                        className="btn-follow"
                                                        onClick={() => {
                                                            setOpenNewsletter(true);
                                                            setIsMenuOrPopupOpen(true);
                                                            ReactGA.event({
                                                                category: "Button",
                                                                action: "Clicked Follow Button",
                                                            });
                                                        }}
                                                    >
                                                        Follow
                                                    </button>

                                                    <button
                                                        className="btn-follow"
                                                        onClick={() => {
                                                            ReactGA.event({
                                                                category: "Button",
                                                                action: "Clicked Progression Button",
                                                            });
                                                        }}
                                                    >
                                                        Progression
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={closeModal}
                            ></button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MapaCustomizado;